<script setup lang="ts">
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();

const props = defineProps({
  food: {
    type: Object,
    default: () => {},
    required: true,
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const availableRawFoods = ref([]);
const rawfoodId = ref();
const quantity = ref();

async function fetchRawFoods(search: String) {
  setLoadingPending();
  try {
    const { data } = await $api.post(`/rawfoods/all`, {
      search,
      page: 1,
    });

    availableRawFoods.value = data;
    setLoadingSuccess();
  } catch (e) {
    setLoadingFailed();
  }
}
async function handleAddPortionToFood() {
  const { data } = await $api.post(
    `/industrialfoods/${props.food.id}/ingredients`,
    {
      rawfoodId: rawfoodId.value,
      quantity: quantity.value,
    },
  );
  props.onConfirm(rawfoodId.value);
  closeModal();
}

fetchRawFoods();
</script>

<template>
  <BaseModal
    :title="`Ajouter un ingrédient à l'aliment ${props.food.label}`"
    :width="900"
  >
    <BaseSelect
      v-model="rawfoodId"
      placeholder="Tomate, abricot, lait, eau, ..."
      type="text"
      label="Recherche un aliment"
      :options="
        availableRawFoods.map((p) => ({
          label: `${p.label} - ${p.description}`,
          value: p.id,
        }))
      "
      :remote-call="fetchRawFoods"
      :is-loading="isLoading"
      clearable
      debounce
    ></BaseSelect>

    <BaseInput
      v-model="quantity"
      type="number"
      label="Quantité (%)"
    ></BaseInput>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />
      <BaseButton text="Confirmer" @click="handleAddPortionToFood" />
    </template>
  </BaseModal>
</template>

<style scoped></style>
